<template>
  <div
    class="flex w-full cursor-pointer border-b border-neutral-high-light p-7"
    :class="data.read_at == null ? 'new' : ''"
    @click="handleViewedNotification"
  >
    <div class="w-full">
      <div class="flex justify-between">
        <p class="mb-2 flex flex-col justify-between">
          <strong class="pr-2 text-neutral-low-dark">{{ data.cta }}</strong>
          <small class="datetime text-neutral-low-ultra">{{ formatDateByMonthAndYearText(data.updated_at) }}</small>
        </p>
        <div class="flex-shrink-0">
          <i
            class="text-neutral-low-dark"
            :class="`uil ${NotificationsIconsEnum[data.icon]}`"
            style="font-size: 2rem !important"
          ></i>
          <!-- data.icon -->
        </div>
      </div>
      <p
        class="mt-2 max-h-[4.5em] overflow-hidden text-ellipsis whitespace-normal leading-[1.5em] text-neutral-low-ultra"
      >
        {{ data.message }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onBeforeMount, ref } from "vue";

import { NotificationsIconsEnum } from "@/enums/NotificationsEnum";
import { updateNotifications } from "@/services/notifications";
import { formatDateByMonthAndYearText } from "@/utils/dateparser/dateHelper";

const data = ref({});

const props = defineProps({
  notification: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["notification-removed"]);

const handleViewedNotification = async () => {
  await updateNotifications({
    ids: [props.notification.id],
  });

  document.location.href = props.notification.url;
};

onBeforeMount(() => {
  data.value = props.notification;
});
</script>

<style lang="scss" scoped>
@import "../../../styles/themes/tailwind/tailwind-light/variables";

.new {
  background-color: $primaryLightColor;
  border-bottom: 1px solid $primaryMediumColor;

  &:hover {
    background-color: $primaryMediumColor;
    border-color: $primaryDarkerColor;
  }

  &:active {
    background-color: var(--twc-primary-default);
    color: $whiteColor;
  }
}
</style>
